import React from "react"
import Tile from "../components/tile"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "../components/link"

const Illustration = ({ transitionStatus, pageContext: { illustration } }) => {
  console.log(illustration)
  return (
    <Layout transitionStatus={transitionStatus}>
      <SEO title={illustration.pageTitle} />

      <h1
        className="text-l"
        style={{
          minHeight: "85vh",
          paddingTop: "7rem",
          display: "inline-block",
        }}
        data-scroll
        data-scroll-speed="-1"
      >
        <div className="line">
          <span>0{illustration.number}</span>
        </div>
        <br />
        {illustration.name.map((line, id) => (
          <div className="line" key={id}>
            <span>{line}</span>
          </div>
        ))}
      </h1>

      <Tile id={illustration.number} />
      <div className="grid15">
        <div
          style={{
            gridColumn: "8/16",
            gridRow: "4",
          }}
        >
          <div className="grid-2-5">
            <div>Architect</div>
            <div>
              {illustration.acritect ? illustration.acritect : "unknown"}
            </div>
          </div>
          <div className="grid-2-5">
            <div>Year</div>
            <div>{illustration.year ? illustration.year : "unknown"}</div>
          </div>
          <div className="grid-2-5">
            <div>Location</div>
            <div>
              {illustration.location ? illustration.location : "unknown"}
              {illustration.map && (
                <>
                  <br />
                  <br />
                  <Link to={illustration.map} className="rounded">
                    view on google maps
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            gridColumn: "8/16",
            gridRow: "8",
          }}
        >
          <div className="photo-container scroll-innert">
            <div className="tile-parallax" data-scroll data-scroll-speed="-2">
              <div
                className={`photo-image`}
                style={{
                  backgroundImage: `url(${illustration.photo.file.url})`,
                }}
              ></div>
            </div>
          </div>
          <p style={{ textAlign: "right" }}>{illustration.copyright}</p>
        </div>
      </div>
    </Layout>
  )
}

export default Illustration
